import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import BannerLanding from '../components/BannerLanding'
import PageHelmet from '../components/PageHelmet';
import Layout from '../components/layout';
import { graphql } from 'gatsby'

const Landing = (props) => (
    <Layout>
        <PageHelmet title="Home" />

        <BannerLanding />

        <div id="main">
            <section id="two" className="spotlights">
                <section id="about" className="home">
                    <Link to="/about" className="image">
                        <Img fluid={props.data.teacherPuzzle.childImageSharp.fluid} />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>About Us</h3>
                            </header>
                            <p>Littlebirds Speech, Language and Literacy is an experienced and friendly paediatric speech, language and literacy service. We are passionate about helping your child develop their speech and language skills to become a successful communicator. We are committed to maintaining high professional standards and engage in ongoing professional development.</p>
                            <ul className="actions">
                                <li><Link to="/about" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section id="services" className="home">
                    <Link to="/services" className="image">
                        <Img fluid={props.data.teacherBlocks.childImageSharp.fluid} />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Services</h3>
                            </header>
                            <p>We provide comprehensive assessment of speech, language and overall communication skills for children. In addition we offer family centred, individualised intervention that is fun and motivating for your child.</p>
                            <ul className="actions">
                                <li><Link to="/services" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                ... on ImageSharp {
                    resize(width: 125, height: 125, rotate: 180) {
                    src
                    }
                }
                }
            }
        },
        teacherPuzzle: file(
            relativePath: { regex: "/teacherPuzzle.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1024
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },

        teacherBlocks: file(
            relativePath: { regex: "/teacherBlocks.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1024
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },
    }
`;

export default Landing